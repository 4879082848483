import React from 'react';

import { Box } from '@core';
import { NewsOutletsRow as NewsOutletsRowComponent } from '@containers';

const NewsOutletsRow = ({ title = 'As seen in', padding }) => (
  <Box {...padding}>
    <NewsOutletsRowComponent title={title} />
  </Box>
);

export default NewsOutletsRow;
